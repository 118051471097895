import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import EvaluationsApi from '../apis/Evaluations';
import EvaluationProgressBar from './EvaluationProgressBar'

const evaluationsApi = new EvaluationsApi(null, null, false);
const PROGRESS_BAR_UPDATE_INTERVAL_SECONDS = 60
const MINIMUM_EVALUATION_PROGRESS_PERCENTAGE = 33
const SATISFACTORY_EVALUATION_PROGRESS_PERCENTAGE = 100

const EvaluationProgressPage = ({ evaluation, setEvaluation }) => {
    const [progress, setProgress] = useState(0);
    const isContinueEnabled = progress >= SATISFACTORY_EVALUATION_PROGRESS_PERCENTAGE;
   
    const onClickCancel = () => evaluationsApi.cancel(evaluation.code).then(r => setEvaluation(r.data))
    const onClickContinue = () => evaluationsApi.finish_successfully(evaluation.code).then(r => setEvaluation(r.data))

    useEffect(() => {
        const fetchAndUpdateProgress = () => {
            evaluationsApi.progress(evaluation.code).then(r => {
                setProgress(r.data)
                evaluationsApi.get(evaluation.code).then(r => setEvaluation(r.data))
            })
        }

        fetchAndUpdateProgress()
        let intervalId = setInterval(fetchAndUpdateProgress, PROGRESS_BAR_UPDATE_INTERVAL_SECONDS * 1000)

        return () => {
            clearInterval(intervalId)
        }
    // DO NOT ADD DEPS HERE!!! IF YOU ADD evaluation HERE YOU GET AN INFINITE LOOP! The lint warnings are stupid!
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container className="text-center mt-5">
            <h1>Battery Assessment</h1>
            <h4>Capturing Data is in progress...</h4>

            <Row className="align-items-center mt-4">
                <Col>
                    <EvaluationProgressBar value={progress} />
                </Col>
                <Col xs="auto">
                    <Button variant="danger" onClick={onClickCancel}>
                        Cancel
                    </Button>
                </Col>
            </Row>

            <p className="mt-4">
                When the test is finished, you will be asked to fill out details on the charging session. You need to be at your car to do this.
            </p>

            <Button variant="primary" disabled={!isContinueEnabled} onClick={onClickContinue}>
                CONTINUE
            </Button>
        </Container>
    );
};

export default EvaluationProgressPage;
