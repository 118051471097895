import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import '../shared/form-elements.css';
import ApiService from '../apis/ApiService';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Invalid email format'
    )
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const apiService = new ApiService(null, null, false);

const LoginForm = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    setError(''); // Clear any previous error messages
    try {
      const params = new URLSearchParams();
      params.append('username', values.email);
      params.append('password', values.password);

      const response = await apiService._post('token/', params);
      if (response.status === 200) {
        const { access_token } = response.data;
        localStorage.setItem('token', access_token);
        navigate('/dashboard');
        window.dispatchEvent(new Event('storage')); // Trigger storage event
      }
    } catch (error) {
      console.error('Error during login:', error);
      const errorMsg = error.response?.data?.detail || 'An error occurred';
      setError(typeof errorMsg === 'string' ? errorMsg : JSON.stringify(errorMsg));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-3 form-group-custom">
            <Form.Label htmlFor="email" className="form-label-custom">Email</Form.Label>
            <Field
              id="email"
              autoComplete="email"
              type="email"
              name="email"
              value={values.email || ''}
              onChange={handleChange}
              className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
            />
            <ErrorMessage component="div" name="email" className="invalid-feedback" />
          </Form.Group>

          <Form.Group className="mb-3 form-group-custom">
            <Form.Label htmlFor="password" className="form-label-custom">Password</Form.Label>
            <Field
              autoComplete="current-password"
              id="password"
              type="password"
              name="password"
              value={values.password || ''}
              onChange={handleChange}
              className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
            />
            <ErrorMessage component="div" name="password" className="invalid-feedback" />
          </Form.Group>

          {error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}

          <Button variant="primary" type="submit" disabled={isSubmitting} className="mt-3">
            {isSubmitting ? 'Logging in...' : 'Log In'}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
