import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, Col, Row } from 'react-bootstrap';

const RegisterSuccess = () => {
  return (
    <>
      <Row>
        <Col md={12}>
          <h1>Registration Successful</h1>
          <p>
            A confirmation email has been sent to your email address. Please check your email and click on the verification link to activate your account. After verifying your email, you can log in using the link below.
          </p>
        </Col>
      </Row>
      <br></br>
      <br></br>
      <Row>
        <Col md={12}>
          <LinkContainer to="/login">
            <Button variant="primary" className="w-100">
              Go to Login
            </Button>
          </LinkContainer>
        </Col>
      </Row>
    </>
  );
};

export default RegisterSuccess;
