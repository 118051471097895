import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

const SohChart = ({ soh }) => {
    // Prepare the chart data inside the component
    const chartData = {
        datasets: [
            {
                data: [soh, 100 - soh], // Example value for battery health
                backgroundColor: ['#E66D57', '#F2B5AA'],
                borderWidth: 1,
            },
        ],
    };

    return (
        <div style={{ width: '250px', margin: 'auto', marginTop: '30px', position: 'relative' }}>
            <Doughnut
                data={chartData}
                options={{
                    cutoutPercentage: 70,
                    tooltips: { enabled: false },
                    hover: { mode: null },
                }}
            />
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '24px',
                    fontWeight: 800,
                    color: '#F76045',
                }}
            >
                {soh.toFixed(2)}%
            </div>
        </div>
    );
};

export default SohChart;
