import React from 'react';
import { Row, Col } from 'react-bootstrap';
import logo from '../header/logo-header-old.svg'; // Placeholder for signature

const SignatureSection = () => {
    return (
        <div style={{ marginTop: '40px', paddingTop: '50px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
            <Row>
                <Col xs={6} style={{ textAlign: 'left' }}>
                    <div>
                        <img src={logo} alt="Olan O'Sullivan Signature" style={{ width: '100px' }} />
                        <p>
                            <strong>Olan O'Sullivan</strong>
                            <br />
                            CEO and Co-Founder
                        </p>
                    </div>
                </Col>
                <Col xs={6} style={{ textAlign: 'right' }}>
                    <div>
                        <img src={logo} alt="Fabiano Pallonetto Signature" style={{ width: '100px' }} />
                        <p>
                            <strong>Fabiano Pallonetto</strong>
                            <br />
                            CTO and Co-Founder
                        </p>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default SignatureSection;
