import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './header/Header';

const Layout = ({ children }) => {
    const location = useLocation();
    const hideHeaderRoutes = ['/evaluations/', '/callback-error/', '/report/'];
    const shouldHideHeader = hideHeaderRoutes.some(route => location.pathname.startsWith(route));

    return (
        <div className="App">
            {!shouldHideHeader && <Header />}
            <div className="App-body">
                {children}
            </div>
        </div>
    );
};

export default Layout;
