import React, { useEffect, useState } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import EvaluationsApi from '../apis/Evaluations';

const evaluationsApi = new EvaluationsApi(null, null, false)

const ThankYouPage = ({ evaluation }) => {
    const [ownerName, setOwnerName] = useState(null)

    useEffect(() => {
        evaluationsApi.getOwner(evaluation.code).then(r => setOwnerName(r?.data?.name))
    }, [evaluation.code])

    return ownerName ? (
        <div className="rbh-container">
            <Row className="text-center justify-content-center">
                <Col md={8}>
                    <h1>Battery Health Test Complete</h1>
                    <p>Thank you for completing the battery health test.</p>
                    <p>
                        The report is being processed. When completed, it will be sent to <b>{ownerName}</b>
                    </p>
                    <p>The report provides an estimate of the State of Health (SoH) of your battery.</p>

                    <Alert variant="info" className="mt-4">
                        You can now safely close this window.
                    </Alert>
                </Col>
            </Row>
        </div>
    ) : null
};

export default ThankYouPage;
