import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import LogoHeader from './LogoHeader.jsx';

const Header = () => {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem('token');

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event('storage')); // Trigger storage event
    navigate('/login');
  };

  return (
    <header className="app-header">
      <Container>
        <Row className="align-items-center">
          <Col>
            <div className="logo-container">
              <LinkContainer to="/">
                <Nav.Link><LogoHeader/></Nav.Link>
              </LinkContainer>
            </div>
          </Col>
          {isAuthenticated && (
            <Col className="text-right">
              <Button variant="outline-danger" onClick={handleLogout}>
                Logout
              </Button>
            </Col>
          )}
        </Row>
        <hr className="separator" />
      </Container>
    </header>
  );
};

export default Header;
