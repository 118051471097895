import Smartcar from '@smartcar/auth';

export const getSmartcarAuthURL = (evaluationCode) => {
    const smart_car_config = {
        clientId: process.env.REACT_APP_SMARTCAR_CLIENT_ID,
        // TODO: Maybe just put the redirect endpoint here and not load from .env?
        redirectUri: process.env.REACT_APP_SMARTCAR_REDIRECT_URI,
        scope: [
            "read_vehicle_info",
            "read_odometer",
            "read_speedometer",
            "read_battery",
            "read_charge",
            "read_charge_locations",
            "read_compass",
            "read_location",
            "read_extended_vehicle_info",
            "read_vin"
        ],
        mode: process.env.REACT_APP_SMARTCAR_MODE,
        onComplete: () => { },
    };
    const smartcar = new Smartcar(smart_car_config);
    return smartcar.getAuthUrl({ state: evaluationCode, singleSelect: true });
};
