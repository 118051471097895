import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Register from './register/Register';
import Login from './login/Login';
import Home from './home/Home';
import Dashboard from './dashboard/Dashboard';
import RegisterSuccess from './register/RegisterSuccess';
import './App.css';
import Evaluation from './evaluation/Evaluation';
import SmartcarCallback from './evaluation/SmartcarCallback';
import GetUrl from './dashboard/GetUrl';
import Layout from './Layout'; // Import the Layout component
import ReportPage from './report/ReportPage';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

    useEffect(() => {
        const handleStorageChange = () => {
            setIsAuthenticated(!!localStorage.getItem('token'));
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <Router>
            <Layout>
                <Routes>
                    <Route
                        path="/register"
                        element={isAuthenticated ? <Navigate to="/dashboard" /> : <Register />}
                    />
                    <Route
                        path="/register-success"
                        element={isAuthenticated ? <Navigate to="/dashboard" /> : <RegisterSuccess />}
                    />
                    <Route
                        path="/login"
                        element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
                    />
                    <Route
                        path="/dashboard"
                        element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/get-url"
                        element={isAuthenticated ? <GetUrl /> : <Login />}
                    />
                    <Route
                        path="/evaluations/:evaluationCode"
                        element={<Evaluation />}
                    />
                    <Route
                        path="/report/:evaluationCode"
                        element={<ReportPage />}
                    />
                    <Route
                        path="/smartcar_callback"
                        element={<SmartcarCallback />}
                    />
                    <Route
                        path="/"
                        element={isAuthenticated ? <Navigate to="/dashboard" /> : <Home />}
                    />
                </Routes>
            </Layout>
        </Router>
    );
};

export default App;
