import { useState, useEffect } from 'react';
import { getSmartcarAuthURL } from './SmartcarUtils';
import ApiService from '../apis/ApiService';

const apiService = new ApiService(null, null, false);

export const useEvaluation = (evaluationCode, error, errorDescription) => {
    const [evaluation, setEvaluation] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!error && !errorDescription) {
            function getEvaluation() {
                return apiService._get(`evaluations/${evaluationCode}/`)
                    .then(response => {
                        if (response.data.vehicle_id) {
                            setEvaluation(response.data);
                            return false;
                        } else {
                            window.location.href = getSmartcarAuthURL(evaluationCode);
                            // Keep loading spinner on, since this is an async action and takes a bit time. Otherwise error while be shown while there is no error!
                            return true;
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching evaluation details:', error);
                    });
            }

            setIsLoading(true)
            getEvaluation().then(s => setIsLoading(s)).catch(() => setIsLoading(false))
        }
    }, [evaluationCode, error, errorDescription]);

    return { evaluation, setEvaluation, isLoading};
};
