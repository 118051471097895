import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { QRCodeCanvas } from 'qrcode.react';
import logo from '../header/logo-header-old.svg';
import EvaluationsApi from '../apis/Evaluations';
import TestDetails from './TestDetails';
import SohChart from './SohChart';
import SignatureSection from './SignatureSection'; // Import the new SignatureSection component

const evaluationsApi = new EvaluationsApi(null, null, false);

const ReportPage = () => {
    const { evaluationCode } = useParams();
    const [evaluation, setEvaluation] = useState(null);

    useEffect(() => {
        evaluationsApi
            .getForReport(evaluationCode)
            .then((r) => setEvaluation(r.data))
            .catch((error) => console.error('Error fetching evaluation details:', error));
    }, [evaluationCode]);

    return (
        evaluation ? (
            <div className="rbh-container" style={{ padding: '20px', textAlign: 'center' }}>
                <Row className="justify-content-center">
                    <Col md={12}>
                        <div style={{ textAlign: 'left', marginBottom: '20px' }}>
                            <img src={logo} alt="BatteryCycle Logo" style={{ width: '150px' }} />
                        </div>
                        <hr></hr>
                        <h1 style={{ fontSize: '24px', color: '#1A73E8', fontWeight: 'bold' }}>
                            Battery Health Test Report
                        </h1>
                        <TestDetails evaluation={evaluation} />
                        <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginTop: '25px' }}>
                            Battery Cycle Score
                        </h1>
                        <SohChart soh={evaluation?.report?.soh} />
                        <h1 style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '20px' }}>
                            Battery is in Good Health
                        </h1>
                        <div style={{ marginTop: '50px' }}>
                            <QRCodeCanvas value={window.location.href} size={100} />
                        </div>
                        <SignatureSection />
                    </Col>
                </Row>
            </div>
        ) : (
            <div>
                <h1 style={{ fontSize: '24px', color: '#1A73E8', fontWeight: 'bold' }}>
                    This test has no report
                </h1>
            </div>
        )
    );
};

export default ReportPage;
