import React from 'react';
import { Row, Col, Card, ListGroup } from 'react-bootstrap';
import { formatIrishDateTime } from '../dashboard/EvaluationsTableColumns';

const TestDetails = ({ evaluation }) => {
    return (
        <div style={{ marginTop: '30px' }}>
            <Card style={{ border: '1px solid #ddd', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Card.Header
                    style={{
                        backgroundColor: '#e2e6ea',
                        color: '#495057',
                        fontWeight: 'normal',
                        fontSize: '16px',
                        padding: '10px 15px',
                        borderBottom: '1px solid #ddd'
                    }}
                >
                    Test Details
                </Card.Header>
                <Card.Body style={{ padding: '20px' }}>
                    <Row>
                        <Col md={6}>
                            <ListGroup variant="flush" style={{ textAlign: 'left' }}>
                                <ListGroup.Item style={{ paddingBottom: '10px' }}>
                                    <strong>Test ID:</strong> {evaluation.code}
                                </ListGroup.Item>
                                <ListGroup.Item style={{ paddingBottom: '10px' }}>
                                    <strong>Vehicle Make:</strong> {evaluation.vehicle?.make?.value}
                                </ListGroup.Item>
                                <ListGroup.Item style={{ paddingBottom: '10px' }}>
                                    <strong>Date and Time:</strong> {formatIrishDateTime(evaluation.finished_at)}
                                </ListGroup.Item>
                                <ListGroup.Item style={{ paddingBottom: '10px' }}>
                                    <strong>Year of Manufacture:</strong> {evaluation.vehicle?.year?.value}
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col md={6}>
                            <ListGroup variant="flush" style={{ textAlign: 'left' }}>
                                <ListGroup.Item style={{ paddingBottom: '10px' }}>
                                    <strong>Vehicle Model:</strong> {evaluation.vehicle?.model?.value}
                                </ListGroup.Item>
                                <ListGroup.Item style={{ paddingBottom: '10px' }}>
                                    <strong>Executed By:</strong> BatteryCycle Limited
                                </ListGroup.Item>
                                <ListGroup.Item style={{ paddingBottom: '10px' }}>
                                    <strong>VIN:</strong> {evaluation.vehicle?.vin?.value}
                                </ListGroup.Item>
                                <ListGroup.Item style={{ paddingBottom: '10px' }}>
                                    <strong>Mileage:</strong> {evaluation.vehicle?.odometer_distance?.value} Km
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
};

export default TestDetails;
