import React from 'react';
import './LogoBodyRight.css';
import logo from './logo-no-background.png';

const LogoBodyRight = () => {

  return (
    <img src={logo} alt="Logo body right" className="logo-body-right" />
  );
};

export default LogoBodyRight;
