import React from 'react';
import { useParams } from 'react-router-dom';
import { evaluationStatuses } from '../dashboard/EvaluationsTableColumns';
import PreliminaryDetailsEntryPage from './PreliminaryDetailsEntryPage';
import ChargingDetailsEntryPage from './ChargingDetailsEntryPage';
import ThankYouPage from './ThankYouPage';
import EvaluationProgressPage from './EvaluationProgressPage';
import EvaluationError from './EvaluationError';
import { useEvaluationQuery } from './useEvaluationQuery';
import { useEvaluation } from './useEvaluation';

const renderComponentForStatus = {
    [evaluationStatuses.NOT_STARTED]: (evaluation, setEvaluation) => (
        <PreliminaryDetailsEntryPage evaluation={evaluation} setEvaluation={setEvaluation} />
    ),
    [evaluationStatuses.RUNNING]: (evaluation, setEvaluation) =>
        evaluation.initial_battery_percentage ? (
            <EvaluationProgressPage evaluation={evaluation} setEvaluation={setEvaluation} />
        ) : (
            <PreliminaryDetailsEntryPage evaluation={evaluation} setEvaluation={setEvaluation} />
        ),
    [evaluationStatuses.SUCCEEDED]: (evaluation, setEvaluation) =>
        evaluation.charging_kwh && evaluation.finished_at ? (
            <ThankYouPage evaluation={evaluation} />
        ) : (
            <ChargingDetailsEntryPage evaluation={evaluation} setEvaluation={setEvaluation} />
        ),
    [evaluationStatuses.CANCELLED]: (evaluation) => (
        <EvaluationError
            evaluationCode={evaluation.code}
            error="Evaluation cancelled"
            errorDescription="The evaluation was cancelled by the user or system."
        />
    ),
    [evaluationStatuses.FAILED]: (evaluation) => (
        <EvaluationError
            evaluationCode={evaluation.code}
            error="Evaluation failed"
            errorDescription="An unexpected error occurred during the evaluation."
        />
    ),
    [evaluationStatuses.FAILED_VEHICLE_ALREADY_RUNNING]: (evaluation) => (
        <EvaluationError
            evaluationCode={evaluation.code}
            error="Evaluation failed"
            errorDescription="Another evaluation is currently running for the same vehicle."
        />
    )
};

const Evaluation = () => {
    const { evaluationCode } = useParams();
    const { error, errorDescription } = useEvaluationQuery();
    const { evaluation, setEvaluation, isLoading } = useEvaluation(evaluationCode, error, errorDescription);

    if (error && errorDescription) {
        return <EvaluationError evaluationCode={evaluationCode} error={error} errorDescription={errorDescription} />;
    }

    if (isLoading) {
        return <div className="spinner">Loading...</div>;
    }

    if (evaluation) {
        const ComponentToRender = renderComponentForStatus[evaluation.status];
        if (ComponentToRender) {
            return ComponentToRender(evaluation, setEvaluation);
        } else {
            return (
                <EvaluationError
                    evaluationCode={evaluation.code}
                    error="Unknown evaluation state"
                    errorDescription="The current evaluation state is not recognized."
                />
            );
        }
    }

    return (
        <EvaluationError
            evaluationCode={evaluationCode}
            error="Unknown evaluation"
            errorDescription="Unable to determine the evaluation state."
        />
    )
};

export default Evaluation;
