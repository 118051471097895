import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { evaluationTableColumns } from './EvaluationsTableColumns';
import DataTable from "react-data-table-component";

const EvaluationsTable = ({ evaluations, error, handleAddEvaluation }) => {
    return (
        <div>
            {error && <Alert variant="danger">{error}</Alert>}
            <div className='table-container'>
                <DataTable
                    columns={evaluationTableColumns}
                    data={evaluations}
                    pagination
                    striped
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 20, 30, 50]}
                    actions={<Button onClick={handleAddEvaluation}>Add a New Test</Button>}
                />
            </div>
        </div>
    );
};

export default EvaluationsTable;
