import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function getColor(value) {
    const colorsStaircase = {
        33: "danger",
        99: "warning",
    }

    const colorStairCaseKeys = Object.keys(colorsStaircase);
    for (let i = 0; i < colorStairCaseKeys.length; ++i) {
        const key = colorStairCaseKeys[i];
        if (value < key) {
            return colorsStaircase[key]
        }
    }

    return "success"
}

function EvaluationProgressBar({ value }) {
    const progressValue = Math.max(0, Math.min(value, 100));

    return (
        <div>
            <ProgressBar>
                <ProgressBar
                    variant={getColor(progressValue)}
                    now={progressValue}
                    key={1}
                />
            </ProgressBar>
            <div style={{ textAlign: 'center', marginTop: '5px' }}>
                {progressValue}% Complete
            </div>
        </div>
    );
}

export default EvaluationProgressBar;
