import React from 'react';
import { Row, Col } from 'react-bootstrap';
import LoginForm from './LoginForm';
import LogoBodyRight from '../shared/LogoBodyRight';
import '../shared/form-containers.css'
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './Login.css'

const Login = () => {
  return (
    <Row className="rbh-container">
      <Col md={12}>
        <Row className="text-start align-items-start">
          <Col md={6} className="rbh-form-container">
            <h1>Log In:</h1>
            <div className="inline-container">
              <h5 className="inline-text">Don't have an account?</h5>
              <LinkContainer to="/register">
                <Nav.Link className="inline-link"><h5 className='rbh-register-link'>Register</h5></Nav.Link>
              </LinkContainer>
            </div>
            <br></br>
            <LoginForm />
          </Col>
          <Col md={6} className="rbh-centered-flex">
            <LogoBodyRight />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Login;
