import React, { useState } from 'react';
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';
import '../shared/form-containers.css';
import EvaluationsApi from '../apis/Evaluations';

const evaluationsApi = new EvaluationsApi(null, null, false);

const PreliminaryDetailsEntryPage = ({ evaluation, setEvaluation }) => {
    const [batteryPercentage, setBatteryPercentage] = useState(null);
    const [email, setEmail] = useState('');
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showContinueModal, setShowContinueModal] = useState(false);

    const handleContinue = () => {
        evaluationsApi.start(evaluation.code, batteryPercentage, email).then(r => {
            setEvaluation(r.data)
        })
    };

    const handleCancel = () => {
        evaluationsApi.cancel(evaluation.code).then(r => setEvaluation(r.data));
    }

    const handleBatteryPercentageChange = e => {
        const value = e.target.value;
        if (value === '' || (Number(value) >= 0 && Number(value) <= 100)) {
            setBatteryPercentage(value);
        }
    };

    return (
        <div className="rbh-container">
            <Row className="text-start align-items-start">
                <Col md={9}>
                    <div>
                        <h1>Battery Assessment</h1>
                        <h4>Charge your car to start your battery health check</h4>
                        <h4>Please enter the following details:</h4>
                    </div>
                    <Form>
                        <Form.Group controlId="batteryPercentage">
                            <Form.Label>Battery percentage at start of charging session</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="e.g., 50"
                                value={batteryPercentage || ''}
                                onChange={handleBatteryPercentageChange}
                                min="0"
                                max="100"
                            />
                        </Form.Group>
                        <Form.Group controlId="email" className="mt-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="e.g., user@example.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Row className="mt-4">
                            <Col className="text-start">
                                <Button variant="secondary" onClick={() => setShowCancelModal(true)}>
                                    Cancel
                                </Button>
                            </Col>
                            <Col className="text-end">
                                <Button variant="primary" onClick={() => setShowContinueModal(true)} disabled={!batteryPercentage}>
                                    Start
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

            {/* Cancel Confirmation Modal */}
            <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Cancellation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to cancel battery assessment?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleCancel}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Continue Confirmation Modal */}
            <Modal show={showContinueModal} onHide={() => setShowContinueModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Start</Modal.Title>
                </Modal.Header>
                <Modal.Body>Is the vehicle plugged in and charging?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowContinueModal(false)}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleContinue}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PreliminaryDetailsEntryPage;
